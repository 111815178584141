
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { useBreakpoints } from '@vueuse/core'
import { ElNotification, ElMessageBox } from 'element-plus'
import { IBank, deleteBankIdShop, getBankList } from '@/api/shop.api'
import BankAddDialog from '@/components/bank/BankAddDialog.vue'
import { BANK_ACCOUNT_TYPE } from '@/config/bank'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'ShopBank',
	components: {
		BankAddDialog,
	},
	setup() {
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const storePermissionShop = computed(() => userStore.getSettingShopPermission)
		const isFetchInitialData = ref<boolean>(true)
		const isSubmitFormLoading = ref<boolean>(false)
		const isShowBankDialog = ref<boolean>(false)
		const breakpoints = useBreakpoints({
			tablet: 1024,
		})
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const isSmallerThanTablet = breakpoints.isSmaller('tablet')
		const shopData = reactive<{bank:IBank[]}>({
			bank: []
		})

		const initialBankFormData = {
			bankId: null,
			shopId: shopStore.selectedShopId,
			accountNumber: '',
			accountName: '',
			accountIssuerId: '',
			accountType: BANK_ACCOUNT_TYPE.BANK_ACCOUNT
		}


		const bankForm = ref({...initialBankFormData })

		const setEditBankData = (bank: any) => {
			bankForm.value.shopId = shopStore.selectedShopId
			bankForm.value.accountNumber = bank.accountNumber
			bankForm.value.accountName = bank.accountName
			bankForm.value.accountIssuerId = bank.accountIssuer?._id
			bankForm.value.bankId = bank._id
			bankForm.value.accountType = bank?.accountType

			isShowBankDialog.value = true // open dialog
		}
		
		const onDeleteBank = (bankId: string) => {
			//
			ElMessageBox.confirm(
				'ยืนยันลบ ธนาคาร?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'error',
					showClose: !isReactNativeWebview.value
				}
			)
				.then(async () => {
					await deleteBankIdShop(bankId)
					ElNotification({
						title: 'สำเร็จ',
						message: 'ลบธนาคารสำเร็จ',
						type: 'success',
						showClose: !isReactNativeWebview.value
					})
					fetchBankList()
				})
		}

		const fetchBankList = () => {
			getBankList(shopStore.selectedShopId as string)
				.then(({ data: { bank } }) => {
					shopData.bank = bank
				}).finally(() => isFetchInitialData.value = false)
		}

		const onSubmitBank = () => {
			fetchBankList()
			bankForm.value = { ...initialBankFormData }
		}

		const onAddBankClick = () => {
			bankForm.value = { ...initialBankFormData } // clear form to default first
			isShowBankDialog.value = true
		}

		onMounted(() => {
			fetchBankList()
		})

		return {
			...toRefs(shopData),
			isSubmitFormLoading,
			isFetchInitialData,
			isShowBankDialog,
			bankForm,
			onSubmitBank,
			isSmallerThanTablet,
			setEditBankData,
			onAddBankClick,
			onDeleteBank,
			storePermissionShop,
			BANK_ACCOUNT_TYPE
		}
	}
})
